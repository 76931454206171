<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>캘린더/상장책자 신청</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="250px">
                    <col width="70px">
                    <col width="120px">
                    <col width="70px">
                    <col width="120px">
                    <col width="60px">
                    <col width="120px">
                    <col width="90px">
                    <col width="120px">
                    <col width="90px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>조회기간</label></th>
                    <td>
                        <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                            </v-menu>
                        </div>
                    </td>
                    <th><label>고객번호</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="INQRY_CUST_NO" @keyup.enter="selectbutton">
                    </td>
                    <th><label>고객명</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="INQRY_CUST_NM" @keyup.enter="selectbutton">
                    </td>
                    <th><label>사번</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="INQRY_CSLT_ID" @keyup.enter="selectbutton">
                    </td>
                    <th><label>신청직원명</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="INQRY_CSLT_NM" @keyup.enter="selectbutton">
                    </td>
                    <td class="is-jh-20">
                        <v-checkbox class="jh-check" v-model="checkbox1" label="미처리"></v-checkbox>
                    </td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="selectbutton">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <div class="jh-form-wrap is-pt-10">
            <div class="jh-ui-header">
                <h2>신청 리스트</h2>
                <div class="is-right">
                    <!-- 필요없다고 함<v-btn class="jh-btn is-light" @click="excelDownload('divGridAplyWorkList', '캘린더/상장책자 신청 리스트 '+TODAY)">엑셀다운로드</v-btn> -->
                </div>
            </div>
            <data-tables
            ref="dataTable"
            id="divGridAplyWorkList"
            dataTableClass=""
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            :paginationOptions="paginationOptions"
            ></data-tables>
        </div>
        <div class="jh-form-wrap is-pt-10 is-mt-10">
            <div class="jh-ui-header">
                <h2>신청 상세정보</h2>
                <div class="is-right">
                    <!-- <v-btn class="jh-btn"  @click="initialization">초기화</v-btn> -->
                    <v-btn class="jh-btn" @click="newAplyWork" :disabled="newApply">신규신청</v-btn>
                    <v-btn class="jh-btn is-main" @click="editAplyWork">수정</v-btn>
                </div>
            </div>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="90px">
                        <col>
                        <col width="90px">
                        <col>
                        <col width="90px">
                        <col>
                        <col width="90px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>신청업무</label></th>
                            <td>
                                <v-select class="jh-form" :items="AW01_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="REGIST_APLY_WORK_CD"></v-select>
                            </td>
                            <th><label>신청일자</label></th>
                            <td colspan="5">
                                <input type="text" class="jh-form is-trns" readonly v-model="REGIST_APLY_DT">
                            </td>
                        </tr>
                        <tr>
                            <th><label>고객번호</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" readonly v-if="popYN === 'Y'" v-model="REGIST_CUST_NO" required>
                                <input type="text" class="jh-form" v-else v-model="REGIST_CUST_NO" required>
                            </td>
                            <th><label>고객명</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" readonly v-if="popYN === 'Y'" v-model="REGIST_CUST_NM">
                                <input type="text" class="jh-form" v-else v-model="REGIST_CUST_NM">
                            </td>
                            <th><label>사번</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" readonly v-model="REGIST_CSLT_ID">
                            </td>
                            <th><label>신청직원명</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" readonly v-model="CSLT_NM">
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2"><label>전화번호</label></th>
                            <td>
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="tel" id="tel_1" value="0" v-model="REGIST_TNO_CD" :disabled="TNO_DISABLED"><label for="tel_1">휴대전화</label>
                                    <input type="text" class="jh-form is-trns" readonly v-model="REGIST_MAIN_TNO" :disabled="TNO_DISABLED">
                                </div>
                            </td>
                            <th rowspan="3"><label>주소</label></th>
                            <td colspan="5">
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="address" id="address_1" value="0" v-model="REGIST_ADDR_CD" :disabled="ADDR1_DISABLED"><label for="address_1">자택주소</label>
                                    <v-select 
                                        class="jh-form is-col-fix" 
                                        style="width: 75px;" 
                                        :items="AW03_COMBO" 
                                        item-text="CD_NM" 
                                        item-value="CD"
                                        placeholder="선택"
                                        v-model="REGIST_OWNHOM_ROAD_NM_YN"
                                        @change="changeAddr('OWNHOM', REGIST_OWNHOM_ROAD_NM_YN, REGIST_OWNHOM_ADR)"
                                        :disabled="ADDR1_DISABLED">
                                    </v-select>
                                    <input type="text" class="jh-form is-txt-center is-col-fix" style="width: 70px;" v-model="REGIST_OWNHOM_PSNO" disabled>
                                    <input type="text" class="jh-form" v-model="REGIST_OWNHOM_ADR" readonly>
                                    <input type="text" class="jh-form is-col-fix" style="width:36.5%" v-model="REGIST_OWNHOM_DETAIL_ADR" readonly>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="tel" id="tel_2" value="1" v-model="REGIST_TNO_CD"><label for="tel_2">직접입력</label>
                                    <input type="text" class="jh-form" :disabled="REGIST_TNO_CD != 1" v-model="REGIST_SELF_TNO" @keyup="changeTnoFormat(REGIST_SELF_TNO)">
                                </div>
                            </td>
                            <td colspan="5">
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="address" id="address_2" value="1" v-model="REGIST_ADDR_CD" :disabled="ADDR2_DISABLED"><label for="address_2">직장주소</label>
                                    <v-select 
                                        class="jh-form is-col-fix" 
                                        style="width: 75px;" 
                                        :items="AW03_COMBO" 
                                        item-text="CD_NM" 
                                        item-value="CD" 
                                        placeholder="선택"
                                        v-model="REGIST_WRC_ROAD_NM_YN"
                                        @change="changeAddr('WRC', REGIST_WRC_ROAD_NM_YN, REGIST_WRC_ADR)"
                                        :disabled="ADDR2_DISABLED">
                                    </v-select>
                                    <input type="text" class="jh-form is-txt-center is-col-fix" style="width: 70px;" v-model="REGIST_WRC_PSNO" disabled>
                                    <input type="text" class="jh-form" v-model="REGIST_WRC_ADR" readonly>
                                    <input type="text" class="jh-form is-col-fix" style="width:36.5%" v-model="REGIST_WRC_DETAIL_ADR" readonly>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th><label>비고</label></th>
                            <td>
                                <input type="text" class="jh-form" v-model="REGIST_RMRK">
                            </td>
                            <td colspan="5">
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="address" id="address_3" value="2" v-model="REGIST_ADDR_CD"><label for="address_3">직접입력</label>
                                    <v-btn class="jh-btn" style="width:75px" title="우편번호 조회" @click="openE010501P02">주소 조회</v-btn>
                                    <input type="text" class="jh-form is-txt-center is-col-fix" style="width: 70px;" v-model="REGIST_SELF_PSNO" :disabled="REGIST_ADDR_CD != 2">                                    
                                    <input type="text" class="jh-form" v-model="REGIST_SELF_ADR" :readonly ="REGIST_ADDR_CD != 2">
                                    <input type="text" class="jh-form is-col-fix" style="width:36.5%" v-model="REGIST_SELF_DETAIL_ADR" placeholder="상세주소 입력하세요">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

        </div>
    </div>
</template>

<script>
import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import { mapGetters } from "vuex";

export default {
    name: "MENU_E010501", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
        popYN: {
            type: String,
            default: ''
        }
    },
    components: {
        dataTables
    },
    data() {
        return {
            headers: [
                { text: '순번', value: 'ROWNUM', align: 'center', width: '50px' },
                { text: '신청일자', value: 'APLY_DT', align: 'center', width: '90px'},
                { text: '고객번호', value: 'CUST_NO', align: 'center', width: '90px'},
                { text: '고객명', value: 'CUST_NM', align: 'center', width: '80px'},
                { text: '우편번호', value: 'PSNO', align: 'center', width: '60px', sortable: false},
                { text: '주소', value: 'ADDR' },
                { text: '연락처', value: 'TNO', align: 'center', width: '120px'},
                { text: '비고', value: 'RMRK', width: '15%', sortable: false},
                { text: '신청직원', value: 'CSLT_ID_NM', align: 'center', width: '90px'},
                { text: '처리자', value: 'PROC_MAN_NM', align: 'center', width: '90px'},
                { text: '처리결과', value: 'PROC_RSLT_NM', align: 'center', width: '80px'},
            ],
            // items: [],

            AW01_COMBO: [],
            AW03_COMBO: [],
            AW04_COMBO: [],
            AW05_COMBO: [],

            menuStartDate: false,
            menuEndDate: false,  
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            TODAY: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            dates: [
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            ],

            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '395px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,
                disableSort: true,
                itemClass: 'isActiveRow',
                load: undefined,
            },
            paginationOptions: {
                totalVisible: 10
            },

            FIRST_INQRY_YN: "Y",

            // 조회조건
            INQRY_CUST_NO: '',
            INQRY_CUST_NM: '',
            INQRY_CSLT_ID: '',
            INQRY_CSLT_NM: '',
            INQRY_PROC_RSLT_CD: '',

            checkbox1: false,

            //저장조건
            REGIST_CUST_NO: '',
            REGIST_APLY_DT: '',
            REGIST_APLY_TIME: '',
            REGIST_CUST_NM: '',
            REGIST_APLY_WORK_CD: '',
            REGIST_CALL_ID: '', //콜ID
            REGIST_ADDR_CD: '', //주소코드

            REGIST_OWNHOM_ROAD_NM_YN: '', //자택도로명여부
            REGIST_OWNHOM_PSNO: '', //자택우편번호
            REGIST_OWNHOM_ADR: '', //자택주소
            REGIST_OWNHOM_DETAIL_ADR: '', //자택상세주소

            REGIST_WRC_ROAD_NM_YN: '', //직장도로명여부
            REGIST_WRC_PSNO: '', //직장우편번호
            REGIST_WRC_ADR: '', //직장주소
            REGIST_WRC_DETAIL_ADR: '', //직장상세주소

            REGIST_SELF_ROAD_NM_YN: '', //직접도로명여부
            REGIST_SELF_PSNO: '', //직접우편번호
            REGIST_SELF_ADR: '', //집접주소
            REGIST_SELF_DETAIL_ADR: '', //직접주소상세

            REGIST_TNO_CD: '', //전화번호코드
            REGIST_MAIN_TNO: '', //메인전화번호 
            REGIST_SELF_TNO: '', //직접전화번호 

            REGIST_CSLT_ID: '', //사번
            REGIST_RMRK: '', //비고 
            REGIST_PROC_MAN: '', //처리자ID
            REGIST_PROC_RSLT_CD: '', //처리결과코드

            TNO_CD1:'',
            TNO_CD2:'',
            CSLT_NM: '', //신청직원명

            TNO_DISABLED: false,
            ADDR1_DISABLED: false,
            ADDR2_DISABLED: false,

            addrObj: {},
            selectedRow: {},
            custInfo: {},
            userInfo: this.$store.getters['userStore/GE_USER_ROLE'],

            newApply: false,
        };
    },

    created() {
        this.dataTableOptions.headers = this.headers;
    },
    mounted(){
        this.getSelBoxList();
        this.setDisabled();

        if (this.popYN === 'Y') {
            // this.INQRY_CSLT_ID = this.userInfo.userId;
            if(this.geCustInfo.SRCH_ACCOUNT != '') {
                this.getCustInfo();
            } else {
                this.common_alert('상장책자 신청 고객 대상 정보가 없습니다.', 'error');
            }
        }
    },
    computed: {
        dataTableInstance() {
            return this.$refs.dataTable;
        },      
        initHeaders(){
            return {
                SERVICE: 'setting.system.cmmn-CmpgnAplyWork-manage',
                METHOD: "",
                TYPE: 'BIZ_SERVICE',
            };
        },
        ...mapGetters({
            geCustInfo: 'callStore/GE_CUST_INFO',
        }),
    },
    watch: {
        "selectedRow": async function (val) {
            if(val.item) {
                // 로우 선택 시, 신규신청 버튼 비활성화
                this.newApply = true;
            }
        },
        "checkbox1": function (val) {
            this.selectbutton();
        },
    },

    methods: {
        openE010501P02(){
            this.openWindow('E010501P02','E010501P02','620','700');
        },
        openWindow(url,name,width,height) {
            let left = (screen.width - width) / 2,
                top = (screen.height - height) / 4
            window.open(url, name, "width="+width +",height="+height+",left="+left+",top="+top);

            window.addEventListener('message', this.recvEvtFromAddr, false);
        },
        recvEvtFromAddr(evt) {
            if (evt.data == null) {
                return;
            }
            let recvObj = JSON.parse(evt.data);

            this.REGIST_SELF_PSNO = recvObj.zPostNo.slice(0, -1);
            this.REGIST_SELF_ADR = recvObj.zRoadAddr;
        },
        fromOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        changeDate(type) {
            if(type == 'start') {
                if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM-DD");
                    this.dates[0] = date;
                    this.from = date;
                    return;
                } else {
                    this.from = this.dates[0];
                }
            } else if (type == 'end') {
                if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM-DD");
                    this.dates[1] = date;
                    this.to = date;
                    return;
                } else {
                    this.to = this.dates[1];
                }
            }
        },
        startDate(e) {
            this.from = e;
        },
        toOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }

                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        endDate(e) {
            this.to = e;
        },
        onClickRow(item, row) { 

            row.select(true);
            row.isSelected = true;
            this.selectedRow = row;
            
            this.REGIST_CUST_NO = item.CUST_NO;
            this.REGIST_APLY_DT = item.APLY_DT;
            this.REGIST_APLY_TIME = item.APLY_TIME;
            this.REGIST_CUST_NM = item.CUST_NM;
            this.REGIST_APLY_WORK_CD = item.APLY_WORK_CD;
            this.REGIST_CALL_ID = item.CALL_ID; //콜ID
            this.REGIST_ADDR_CD = item.ADDR_CD; //주소코드

            this.REGIST_OWNHOM_ROAD_NM_YN = item.OWNHOM_ROAD_NM_YN; //자택도로명여부
            this.REGIST_OWNHOM_PSNO = item.OWNHOM_PSNO; //자택우편번호
            this.REGIST_OWNHOM_ADR = item.OWNHOM_ADR; //자택주소
            this.REGIST_OWNHOM_DETAIL_ADR = item.OWNHOM_DETAIL_ADR; //자택상세주소

            this.REGIST_WRC_ROAD_NM_YN = item.WRC_ROAD_NM_YN; //직장도로명여부
            this.REGIST_WRC_PSNO = item.WRC_PSNO; //직장우편번호
            this.REGIST_WRC_ADR = item.WRC_ADR; //직장주소
            this.REGIST_WRC_DETAIL_ADR = item.WRC_DETAIL_ADR; //직장상세주소

            this.REGIST_SELF_ROAD_NM_YN = item.SELF_ROAD_NM_YN; //직접도로명여부
            this.REGIST_SELF_PSNO = item.SELF_PSNO; //직접우편번호
            this.REGIST_SELF_ADR = item.SELF_ADR; //집접주소
            this.REGIST_SELF_DETAIL_ADR = item.SELF_DETAIL_ADR; //직접주소상세

            this.REGIST_TNO_CD = item.TNO_CD; //전화번호코드
            this.REGIST_MAIN_TNO = item.MAIN_TNO.indexOf('-') < 0 ? this.mixin_telNumFormat(item.MAIN_TNO) : item.MAIN_TNO; //메인전화번호 
            this.REGIST_SELF_TNO = item.SELF_TNO.indexOf('-') < 0 ? this.mixin_telNumFormat(item.SELF_TNO) : item.SELF_TNO; //직접전화번호 

            this.REGIST_CSLT_ID = item.CSLT_ID; //사번
            this.REGIST_RMRK = item.RMRK; //비고 
            this.REGIST_PROC_MAN = item.PROC_MAN; //처리자ID
            this.REGIST_PROC_RSLT_CD = item.PROC_RSLT_CD; //처리결과코드

            this.CSLT_NM = item.USER_NM;
        },

        selectbutton() {
            this.initialization();
            this.dataTableOptions.load = this.selectAplyWork;

            if (this.dataTableOptions.page > 1)this.dataTableOptions.page = 1;
            else this.dataTableInstance.loadData();
            
        },

        async selectAplyWork(loadOptions){

            const table = this.$el.children[2].children[1].children[0].children[0];
            table.scrollTop = 0;

            // 신규신청 버튼 활성화
            this.newApply = false;

            if (this.selectedRow.isSelected) {
                this.selectedRow.select(false);
                this.selectedRow.isSelected = false;
            }
            
            if (this.FIRST_INQRY_YN == "Y"){
                return this.FIRST_INQRY_YN = "N"
            }

            if (!this.selectValidate()) {
                return;
            }

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };
            requestData.headers["URL"] = "/api/phone/cmpgn/aply-work/list";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            requestData.headers["PAGES_CNT"] = loadOptions.page; 

            requestData.sendData["STR_APLY_DT"] = this.dates[0].replace(/\-/g, "");
            requestData.sendData["END_APLY_DT"] = this.dates[1].replace(/\-/g, "");
            requestData.sendData["CUST_NO"] = this.INQRY_CUST_NO;
            requestData.sendData["CUST_NM"] = this.INQRY_CUST_NM;
            requestData.sendData["CSLT_ID"] = this.INQRY_CSLT_ID;
            requestData.sendData["CSLT_NM"] = this.INQRY_CSLT_NM;
            
            if (this.checkbox1){
                requestData.sendData["PROC_RSLT_CD"] = '0';
            }

            const response = await this.common_postCall(requestData);
            // this.initialization();

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                let header = response.HEADER;
                let data = response.DATA;

                data.forEach(item => {
                    item.TNO = item.TNO.indexOf('-') < 0 ? this.mixin_newPhoneFormat(item.TNO) : this.mixin_newPhoneFormat(item.TNO.replace(/\-/g, ''));
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT
                };
            }
        },
        newAplyWork() {
            if(this.selectedRow.select == undefined || !this.selectedRow.isSelected) {
                this.common_confirm('신규신청 하시겠습니까?', this.saveAplyWork, null, null, null, "noti");
            }
        },
        editAplyWork() {
            if (this.selectedRow.isSelected) {
                this.REGIST_PROC_RSLT_CD = '1';
                console.log(this.REGIST_PROC_RSLT_CD);
                this.common_confirm('상세정보를 수정하시겠습니까?', this.saveAplyWork, null, null, null, "noti");
            } else {
                this.common_alert(`선택된 데이터가 없습니다.`);
                return;
            }
        },

        async saveAplyWork(){
            if(this.mixin_isEmpty(this.REGIST_PROC_RSLT_CD)) {
                this.setNewApplyInfo(); // 상세정보 세팅
            }
            
            if (!this.validate()) {
                return;
            }

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            let message = '';

            if (this.mixin_isEmpty(this.REGIST_PROC_RSLT_CD)){
                requestData.headers["URL"] = "/api/phone/cmpgn/aply-work/insert";
                requestData.headers["METHOD"] = "register";

                message = '정상적으로 등록되었습니다.';

                this.REGIST_PROC_RSLT_CD = 0;
            }else{
                requestData.headers["URL"] = "/api/phone/cmpgn/aply-work/update";
                requestData.headers["METHOD"] = "update";

                message = '정상적으로 수정되었습니다.'
            }

            requestData.headers["ASYNC"] = false;

            requestData.sendData["CUST_NO"] = this.REGIST_CUST_NO;
            requestData.sendData["APLY_DT"] = this.REGIST_APLY_DT.replaceAll('-', '');
            requestData.sendData["APLY_TIME"] = this.REGIST_APLY_TIME;
            requestData.sendData["CUST_NM"] = this.REGIST_CUST_NM;
            requestData.sendData["APLY_WORK_CD"] = this.REGIST_APLY_WORK_CD; //신청업무코드
            requestData.sendData["CALL_ID"] = this.REGIST_CALL_ID;
            requestData.sendData["ADDR_CD"] = this.REGIST_ADDR_CD;

            requestData.sendData["OWNHOM_ROAD_NM_YN"] = this.REGIST_OWNHOM_ROAD_NM_YN; //자택도로명여부
            requestData.sendData["OWNHOM_PSNO"] = this.REGIST_OWNHOM_PSNO; //자택우편번호
            requestData.sendData["OWNHOM_ADR"] = this.REGIST_OWNHOM_ADR; //자택주소
            requestData.sendData["OWNHOM_DETAIL_ADR"] = this.REGIST_OWNHOM_DETAIL_ADR; //자택상세주소

            requestData.sendData["WRC_ROAD_NM_YN"] = this.REGIST_WRC_ROAD_NM_YN; //직장도로명여부
            requestData.sendData["WRC_PSNO"] = this.REGIST_WRC_PSNO; //직장우편번호
            requestData.sendData["WRC_ADR"] = this.REGIST_WRC_ADR; //직장주소
            requestData.sendData["WRC_DETAIL_ADR"] = this.REGIST_WRC_DETAIL_ADR; //직장상세주소

            if(!this.mixin_isEmpty(this.REGIST_SELF_PSNO)) this.REGIST_SELF_ROAD_NM_YN = 'Y';
            requestData.sendData["SELF_ROAD_NM_YN"] = this.REGIST_SELF_ROAD_NM_YN; //직접도로명여부
            requestData.sendData["SELF_PSNO"] = this.REGIST_SELF_PSNO; //직접우편번호
            requestData.sendData["SELF_ADR"] = this.REGIST_SELF_ADR; //집접주소
            requestData.sendData["SELF_DETAIL_ADR"] = this.REGIST_SELF_DETAIL_ADR; //직접주소상세

            requestData.sendData["TNO_CD"] = this.REGIST_TNO_CD; //전화번호코드
            requestData.sendData["MAIN_TNO"] = this.REGIST_MAIN_TNO; //메인전화번호 
            requestData.sendData["SELF_TNO"] = this.REGIST_SELF_TNO; //직접전화번호 

            requestData.sendData["CSLT_ID"] = this.REGIST_CSLT_ID; //사번
            requestData.sendData["RMRK"] = this.REGIST_RMRK; //비고 
            requestData.sendData["PROC_RSLT_CD"] = this.REGIST_PROC_RSLT_CD; //처리결과코드 
            requestData.sendData["PROC_MAN"] =  this.REGIST_PROC_MAN; //처리자ID
            requestData.sendData["REG_ID"] = this.userInfo.userId;
            requestData.sendData["UPD_ID"] = this.userInfo.userId;

            if (this.checkbox1){
                requestData.sendData["PROC_RSLT_CD"] = '0';
            }

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                this.common_alert(message, 'done');
                this.initialization();
                this.dataTableInstance.loadData();
            }
        },

        initialization(){
            this.REGIST_APLY_WORK_CD = '';
            this.REGIST_APLY_DT = '';
            this.REGIST_CUST_NO = '';
            this.REGIST_CUST_NM = '';
            this.REGIST_CSLT_ID = '';
            this.CSLT_NM = '';
            this.REGIST_TNO_CD = '';
            this.REGIST_MAIN_TNO = '';
            this.REGIST_ADDR_CD = '';
            this.REGIST_OWNHOM_ROAD_NM_YN = '';
            this.REGIST_OWNHOM_PSNO = '';
            this.REGIST_OWNHOM_ADR = '';
            this.REGIST_OWNHOM_DETAIL_ADR = '';
            this.REGIST_TNO_CD = '';
            this.REGIST_SELF_TNO = '';
            this.REGIST_ADDR_CD = '';
            this.REGIST_WRC_PSNO = '';
            this.REGIST_WRC_ADR = '';
            this.REGIST_WRC_DETAIL_ADR = '';
            this.REGIST_ADDR_CD = '';
            this.REGIST_SELF_PSNO = '';
            this.REGIST_SELF_ADR = '';
            this.REGIST_SELF_DETAIL_ADR = '';
            this.REGIST_RMRK = '';
        },
        //validation체크
        selectValidate() {
            let val = true;
            if (this.mixin_isEmpty(this.dates[0])){
                this.common_alert(`조회시작일자가 없습니다`,'error')
                val = false;
            }else if (this.mixin_isEmpty(this.dates[1])){
                this.common_alert(`조회종료일자가 없습니다`,'error')
                val = false;
            }

            return val;
        },
        validate() {
            
            let val = true;
            if (this.mixin_isEmpty(this.REGIST_CUST_NO)){
                this.common_alert(`고객번호가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else if (this.mixin_isEmpty(this.REGIST_APLY_DT)){
                this.common_alert(`신청일자가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else if (this.mixin_isEmpty(this.REGIST_APLY_WORK_CD)){
                this.common_alert(`신청업무가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else if ( this.mixin_isEmpty(this.REGIST_TNO_CD)){
                this.common_alert(`선택된 전화번호가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else if (this.REGIST_TNO_CD == '0' && this.mixin_isEmpty(this.REGIST_MAIN_TNO)){
                this.common_alert(`휴대전화번호가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else if (this.REGIST_TNO_CD == '1'  && this.mixin_isEmpty(this.REGIST_SELF_TNO)){
                this.common_alert(`전화번호가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else if ( this.mixin_isEmpty(this.REGIST_ADDR_CD)){
                this.common_alert(`선택된 주소가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else if (this.REGIST_ADDR_CD == '0'){
                if (this.mixin_isEmpty(this.REGIST_OWNHOM_ROAD_NM_YN)){
                    this.common_alert(`해당 주소의 지번또는 도로명 여부를 선택해주세요`,'error')
                    val = false;
                }else if (this.mixin_isEmpty(this.REGIST_OWNHOM_ADR)){
                    this.common_alert(`해당 주소의 주소가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                    val = false;
                }else if (this.mixin_isEmpty(this.REGIST_OWNHOM_DETAIL_ADR)){
                    this.common_alert(`해당 주소의 주소상세가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                    val = false;
                }
            }else if (this.REGIST_ADDR_CD == '1'){
                if (this.mixin_isEmpty(this.REGIST_WRC_ROAD_NM_YN)){
                    this.common_alert(`해당 주소의 지번또는 도로명 여부를 선택해주세요`,'error')
                    val = false;
                }else if (this.mixin_isEmpty(this.REGIST_WRC_ADR)){
                    this.common_alert(`해당 주소의 주소가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                    val = false;
                }else if (this.mixin_isEmpty(this.REGIST_WRC_DETAIL_ADR)){
                    this.common_alert(`해당 주소의 주소상세가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                    val = false;
                }
            }else if (this.REGIST_ADDR_CD == '2'){
                // if (this.mixin_isEmpty(this.REGIST_SELF_ROAD_NM_YN)){
                //     this.common_alert(`해당 주소의 지번또는 도로명 여부를 선택해주세요`,'error')
                //     val = false;
                // }else 
                if (this.mixin_isEmpty(this.REGIST_SELF_ADR)){
                    this.common_alert(`해당 주소의 주소가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                    val = false;
                }else if (this.mixin_isEmpty(this.REGIST_SELF_DETAIL_ADR)){
                    this.common_alert(`해당 주소의 주소상세가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                    val = false;
                }
            }

            return val;
        },
        async getSelBoxList() {
            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
            requestData.headers["METHOD"] = "selectCode";
            requestData.headers["ASYNC"] = false;

            let sendList = [];
            sendList.push("AW01"); //신청업무코드
            sendList.push("AW02"); //주소코드
            sendList.push("AW03"); //도로명여부
            sendList.push("AW04"); //전화번호코드
            sendList.push("AW05"); //처리결과코드

            requestData.sendData["GROUP_CD"] = sendList;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

                for (var i = 0; i < response.DATA.length ; i++){

                    if (response.DATA[i].GROUP_CD == "AW01"){
                        this.AW01_COMBO.push(response.DATA[i]);
                    }else if (response.DATA[i].GROUP_CD == "AW03"){
                        this.AW03_COMBO.push(response.DATA[i]);
                    }else if (response.DATA[i].GROUP_CD == "AW04"){
                        this.AW04_COMBO.push(response.DATA[i]);
                    }else if (response.DATA[i].GROUP_CD == "AW05"){
                        this.AW05_COMBO.push(response.DATA[i]);
                    }
                }
            }
        },
        
        excelDownload(table_id, file_name) {
            //number,date,time,string - n,d,t,s
            var exceltypeArr = {};
            exceltypeArr[0] = "n";           
            exceltypeArr[1] = "s";  
            exceltypeArr[2] = "s";  
            exceltypeArr[3] = "s";       
            exceltypeArr[4] = "s";     
            exceltypeArr[5] = "s";     
            exceltypeArr[6] = "s"; 
            exceltypeArr[7] = "s"; 
            exceltypeArr[8] = "s"; 
            exceltypeArr[9] = "s"; 
            exceltypeArr[10] = "s";


            this.table_id = table_id
            this.file_name = file_name
            this.mixin_common_exportExcel(exceltypeArr)
        },
        getCustInfo() {
            this.initialization();

            let today = new Date();
            let year = today.getFullYear();
            let month = today.getMonth() + 1;
            let date = today.getDate();

            let month_format = ('00'+month.toString()).slice(-2);
            let date_format = ('00'+date.toString()).slice(-2);

            this.REGIST_APLY_DT = year + '-' + month_format + '-' + date_format;
            this.REGIST_TNO_CD = '0';
            this.REGIST_ADDR_CD = '0';
            this.REGIST_CUST_NO = this.geCustInfo.CLNT_NO;        // 고객번호
            this.REGIST_CUST_NM = this.geCustInfo.CNM;            // 고객명
            this.REGIST_MAIN_TNO = this.geCustInfo.MOVE_TNO.indexOf('-') < 0 ? 
                this.mixin_newPhoneFormat(this.geCustInfo.MOVE_TNO) : this.mixin_newPhoneFormat(this.geCustInfo.MOVE_TNO.replace(/\-/g, ''));      // 전화번호
            this.REGIST_OWNHOM_PSNO = this.geCustInfo.HOME_PSNO;  // 우편번호(자택)
            this.REGIST_OWNHOM_ADR = this.geCustInfo.HOME_ADDR;   // 주소(자택)
            this.REGIST_OWNHOM_DETAIL_ADR = this.geCustInfo.HOME_ADDR_DETAIL; // 상세주소(자택)
            this.REGIST_WRC_PSNO = this.geCustInfo.OFFC_PSNO;     // 우편번호(직장)
            this.REGIST_WRC_ADR = this.geCustInfo.OFFC_ADDR;      // 주소(직장)
            this.REGIST_WRC_DETAIL_ADR = this.geCustInfo.OFFC_ADDR_DETAIL;    // 상세주소(직장)
            this.REGIST_CSLT_ID = this.userInfo.userId; // 신청직원
            this.CSLT_NM = this.userInfo.userName;        // 신청직원명

            if(!this.mixin_isEmpty(this.geCustInfo.SRCH_ACCOUNT)) {
                this.getAccountInfo(this.geCustInfo.SRCH_ACCOUNT);
            }
        },
        async getAccountInfo(acntNo) {
            let URLData = '/api/phone/main/acnt-addr/inqire';
            let HEADER_SERVICE = 'phone.main.acnt-addr';
            let HEADER_METHOD = 'inqire';
            let HEADER_TYPE = 'BIZ_SERVICE';
                    
            let params = {}
            params.ACNT_NO = acntNo;
            params.USER_ID = this.userInfo.userId;
            params.USER_NM = this.userInfo.userName;
            params.ASP_CUST_KEY = '001';

            await api
                .post(URLData, params,
                {
                    head: {
                        SERVICE: HEADER_SERVICE,
                        METHOD: HEADER_METHOD,
                        TYPE: HEADER_TYPE,
                        ASYNC: false,
                    },
                })
                .then((response) => {
                    if (response.data.DATA[0]) {
                        console.log(JSON.stringify(response.data));
                        let resAccData = JSON.parse(JSON.stringify(response.data.DATA[0]));

                        this.REGIST_OWNHOM_ROAD_NM_YN = resAccData.HOME_ADDR_TYPE;
                        this.REGIST_WRC_ROAD_NM_YN = resAccData.OFFC_ADDR_TYPE;
                    } else {
                        this.initialization();
                        this.common_alert('상장책자 신청 고객 대상 정보가 없습니다.', 'error');
                    }
                })
                .catch((err) => {
                    this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error');
                    console.log(err);
                });
        },
        setNewApplyInfo() {
            this.REGIST_PROC_RSLT_CD = '';
                
            let today = new Date();
            let year = today.getFullYear();
            let month = today.getMonth() + 1;
            let date = today.getDate();

            let month_format = ('00'+month.toString()).slice(-2);
            let date_format = ('00'+date.toString()).slice(-2);

            this.REGIST_APLY_DT = year + '-' + month_format + '-' + date_format;
            this.REGIST_CSLT_ID = this.userInfo.userId;   // 신청직원
            this.CSLT_NM = this.userInfo.userName;        // 신청직원명
        },
        setDisabled() {
            if (this.popYN != 'Y') {
                this.TNO_DISABLED = true;
                this.ADDR1_DISABLED = true;
                this.ADDR2_DISABLED = true;
            }
        },
        changeTnoFormat(val) {
            if(val.length > 8) {
                this.REGIST_SELF_TNO = this.mixin_newPhoneFormat(val.replace(/[^0-9]/g, ''));
            }
        },
        async changeAddr(type, val, addr) {
            let value = '';

            if (val == 'N') {
                value = 'Y';
            } else if (val == 'Y') {
                value = 'N';
            }

            if(this.mixin_isEmpty(addr)) {
                type == 'WRC' ? this.WRC_ROAD_NM_YN = value : this.OWNHOM_ROAD_NM_YN = value; 
                this.common_toast(`주소를 확인해주세요.`, 'info');
                return false;
            } else if (this.validateAddr(addr)) {
                type == 'WRC' ? this.WRC_ROAD_NM_YN = value : this.OWNHOM_ROAD_NM_YN = value; 
                this.common_toast(`정확한 정보를 가져올 수 없습니다.`, 'error');
                return false;
            }

            let requestData = {
                headers: {
                    SERVICE: "phone.main.post-addr",
                    METHOD: "",
                    TYPE: 'BIZ_SERVICE',
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/phone/main/post-addr/inqire";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            requestData.sendData["ADDR_NO"] = addr;
            requestData.sendData["szCont"] = 'N';

            const response = await this.common_postCall(requestData);
            const header = response.HEADER;

            let resAddr = '';

            if (header.ERR_FLAG) {
                this.common_alert(header.ERR_MSG, 'error');
                return false;
            } else {
                if(response.DATA.length > 1) {
                    this.common_toast(`정확한 정보를 가져올 수 없습니다.`, 'error');
                } else {
                    if(val == "N") resAddr = response.DATA[0].zLotNoAddr;
                    if(val == "Y") resAddr = response.DATA[0].zRoadAddr;
    
                    let result = this.getAddr(resAddr.trimEnd());
    
                    if(type == "OWNHOM") {
                        this.REGIST_OWNHOM_ADR = result;
                    } else if(type == "WRC") {
                        this.REGIST_WRC_ADR = result;
                    }
                }
            }

        },
        validateAddr(addr) {
            // 구주소인지 신주소 인지 체크
            let result = false;
            let srchNo = 0;
            const splAddr = addr.split(' ');

            if(/[0-9]/.test(splAddr[splAddr.length-1])) {
                srchNo = splAddr.length-1;
            } else if (/[0-9]/.test(splAddr[splAddr.length-2])) {
                srchNo = splAddr.length-2;
            }

            if (srchNo == 0) {
                result = true;
            } 

            return result;
        },
        getAddr(addr) {

            const splAddr = addr.split(' ');
            let srchAddr = '';
            let srchNo = 0;

            if(/[0-9]/.test(splAddr[splAddr.length-1])) {
                srchNo = splAddr.length-1;
            } else if (/[0-9]/.test(splAddr[splAddr.length-2])) {
                srchNo = splAddr.length-2;
            }

            splAddr.forEach((item, idx) => {
                if(idx <= srchNo) {
                    srchAddr = srchAddr + item + ' ';
                }
            });

            return srchAddr;
        }

    },
    
};
</script>

<style></style>